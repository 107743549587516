<template>
	<div class="generatechart" data-aos="slide-down" data-aos-delay="0">
		<div v-if="showData.T_data==''">
			<noData></noData>
			<div style="color: #ccc;font-size: 12px;text-align: center;">哎呀，没有视图哦</div>
		</div>
		<div v-else style="width: 100%;height: 100%;display: flex;overflow-y: auto;">
			<iframe v-if="_noUnderline()" style="width: 100%; flex:1;" :src="`https://cold.coldbaozhida.com/Cold_DataV/?T_key=${showFirm.plan}&T_mode=1`" allowfullscreen="allowfullscreen" frameborder="0" data-id="1"></iframe>
			<iframe v-else style="width: 100%; flex:1;" :src="`https://vdata.baozhida.cn/admin/View?ProjectID=${showData.T_data}&Data=${token}`" allowfullscreen="allowfullscreen" frameborder="0" data-id="1"></iframe>
		</div>
	</div>
</template>

<script>
	import {mapGetters} from "vuex"
	export default {
		computed:mapGetters(["showData","showFirm"]),
		data() {
			return {
				token:this.$store.state.userInfo.token
			}
		},
		watch:{
			showFirm:{
				handler(newData){
					console.log('地址变化 ',newData)
				},immediate:true,deep:true
			}
		},
		methods:{
			_noUnderline(){
				
				var str=this.showData.T_data;
				var str1 = str.replace(/\_+/g,"")
				console.log('str',str,str1,str.length,str1.length)
				if(str.length==str1.length){//没有下划线
					return false
				}else{//有下划线
					return true
				}
			}
		},
		created() {
			// window.open('https://osscold.baozhida.cn/Tool/DataTool.htm','_blank')
		},
		mounted() {
			console.log('state.showFirm',`https://cold.coldbaozhida.com/Cold_DataV/?T_key=${this.showFirm.plan}`)
			////console.log('999',this.showData,`https://vdata.baozhida.cn/admin/View?T_ViewId=${this.showData.T_data}&Data=${this.$store.state.userInfo.token}`)
		}
	}
</script>

<style lang="scss">
	.generatechart{
		height: 100vh;
	}
</style>
